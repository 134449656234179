import {
  duration
} from 'moment'

class Pace {
  constructor(element) {
    this.element = element

    this.buttons = element.querySelectorAll('.unit-selector button')
    this.calculateElm = element.querySelector('.calculate')
    this.clearElm = element.querySelector('.clear')
    this.distance = element.querySelector('#distance')
    this.pace = {
      minutes: element.querySelector('#paceMinutes'),
      seconds: element.querySelector('#paceSeconds')
    }
    this.time = {
      hours: element.querySelector('#hours'),
      minutes: element.querySelector('#minutes'),
      seconds: element.querySelector('#seconds')
    }

    this.addListeners()
  }

  addListeners () {
    const toggleButtons = ev => this.toggleButtons(ev)
    const calculate = ev => this.calculate(ev)
    const clear = ev => this.clearCells(ev)

    if (this.buttons.length) {
      for (const btn of this.buttons) {
        btn.addEventListener('click', toggleButtons)
      }
    }

    this.calculateElm.addEventListener('click', calculate)
    this.clearElm.addEventListener('click', clear)
  }

  toggleButtons (ev) {
    for (const b of this.buttons) {
      b.classList.remove('selected')
    }

    const type = ev.target.textContent.toLowerCase()

    if (type === 'miles') {
      this.changeToMiles()
    }

    if (type === 'km') {
      this.changeToKm()
    }

    ev.target.classList.add('selected')
  }

  changeToKm () {
    const elms = this.element.querySelectorAll('.unit')
    for (const elm of elms) {
      if (elm.classList.contains('unit-distance')) {
        elm.textContent = 'km'
      }

      if (elm.classList.contains('unit-pace')) {
        elm.textContent = '/km'
      }
    }
  }

  changeToMiles () {
    const elms = this.element.querySelectorAll('.unit')
    for (const elm of elms) {
      if (elm.classList.contains('unit-distance')) {
        elm.textContent = 'Miles'
      }

      if (elm.classList.contains('unit-pace')) {
        elm.textContent = '/mile'
      }
    }
  }

  clearCells (ev) {
    this.distance.value = ''
    this.pace.minutes.value = ''
    this.pace.seconds.value = ''
    this.time.hours.value = ''
    this.time.minutes.value = ''
    this.time.seconds.value = ''
  }

  calculate (ev) {
    const distance = +this.distance.value
    let time = 0
    let pace = 0
    
    if (this.time.hours.value || this.time.minutes.value || this.time.seconds.value) {
      time = (+this.time.hours.value * 60) + +this.time.minutes.value + (+this.time.seconds.value / 60)
    }

    if (this.pace.minutes.value || this.pace.seconds.value) {
      pace = (+this.pace.minutes.value) + (+this.pace.seconds.value / 60)
    }

    if (pace && distance) {
      this.calculateTime(pace, distance)
      return
    }

    if (pace && time) {
      this.calculateDistance(pace, time)
      return
    }

    if (time && distance) {
      this.calculatePace(time, distance)
      return
    }

    alert('haway man enter some values')
  }

  calculateTime (pace, distance) {
    const time = pace * distance

    const momDuration = duration(time, 'minutes')

    this.time.seconds.value = momDuration.get('seconds')
    this.time.minutes.value = momDuration.get('minutes')
    this.time.hours.value = momDuration.get('hours')
  }

  calculatePace (time, distance) {
    const pace = time / distance

    const momPace = duration(pace, 'minutes')
    this.pace.minutes.value = momPace.get('minutes')
    this.pace.seconds.value = momPace.get('seconds')
  }

  calculateDistance (pace, time) {
    this.distance.value = (pace * time) / 60
  }
}

export default Pace
